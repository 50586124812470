import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clipboard"
export default class extends Controller {
	static targets = ["source"];

	selectText() {
		const range = document.createRange();
		range.selectNodeContents(this.sourceTarget);

		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
	}

	copy(event) {
		this.selectText();
		navigator.clipboard.writeText(this.sourceTarget.innerText);
	}
}
