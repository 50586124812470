import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="equipment-controller"
export default class extends Controller {
	static targets = ["select"];

	connect() {
		for (const target of this.selectTargets) {
			if (!target.checked) {
				continue;
			}

			this.previous = target.value;
			this.change(target);
		}
	}

	change(target) {
		this.element.classList.remove(`odo-inventory__${this.previous}-enabled`);
		this.element.classList.add(`odo-inventory__${target.value}-enabled`);
		this.previous = target.value;
	}

	update(event) {
		const concept = event.currentTarget.dataset.concept;
		for (const target of this.selectTargets) {
			if (target.dataset.concept != concept) {
				continue;
			}

			this.change(target);
			target.checked = true;
		}
	}
}
