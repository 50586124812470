import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="self-remove"
export default class extends Controller {
	static classes = ["removing"];
	static values = { timer: { type: Number, default: 0 } };
	static outlets = ["autosubmit"];

	remove() {
		if (this.timerValue > 0) {
			if (this.hasRemovingClass) this.element.classList.add(...this.removingClasses);
			setTimeout(() => this.element.remove(), this.timerValue);
		} else {
			const parent = this.element.parentElement;
			this.element.remove();

			// trims whitespaces from parent element
			if (parent.innerHTML.trim() === "") parent.innerHTML = "";
		}
		this.autosubmitOutlets.forEach((autosubmit) => autosubmit.immediateSubmit());
	}
}
