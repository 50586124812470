import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attribute-modifier"
export default class extends Controller {
	static targets = ["attribute", "modifier"];

	connect() {
		this.calculate();
	}

	calculate() {
		if (!this.hasAttributeTarget || this.attributeTarget.value === undefined || this.attributeTarget.value === "") {
			this.modifierTarget.textContent = "∅";
			return;
		}

		this.modifierTarget.textContent = this.attributeToModifier(this.attributeTarget.value);
	}

	attributeToModifier(string) {
		const number = parseInt(string, 10);

		if (number <= 3) return "-3";
		if (number <= 5) return "-2";
		if (number <= 8) return "-1";
		if (number <= 12) return "0";
		if (number <= 14) return "+1";
		if (number <= 16) return "+2";
		if (number <= 18) return "+3";
		return "+4";
	}
}
