import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="top-navigation-drawer"
export default class extends Controller {
	static targets = ["drawer", "button"];

	connect() {
		this.open = false;
	}

	disconnect() {
		this.close();
	}

	oppenedMenuDrawerHandle = () => {
		if (!this.open) {
			return;
		}
		this.close();
	};

	close() {
		this.drawerTarget.classList.add("invisible");
		this.drawerTarget.classList.remove("z-40");
		this.buttonTarget.classList.remove("text-carmesim-500");
		window.removeEventListener("oppenedMenuDrawer", this.oppenedMenuDrawerHandle);
		document.removeEventListener("click", this.closeDrawer.bind(this));
		this.open = false;
	}

	openDrawer(event) {
		event.stopPropagation();
		if (this.open === true) {
			this.closeDrawer(event);
			return;
		}
		document.addEventListener("click", this.closeDrawer.bind(this));
		window.addEventListener("oppenedMenuDrawer", this.oppenedMenuDrawerHandle);
		window.dispatchEvent(new CustomEvent("oppenedMenuDrawer"));
		this.drawerTarget.classList.remove("invisible");
		this.drawerTarget.classList.add("z-40");
		this.buttonTarget.classList.add("text-carmesim-500");
		this.open = true;
	}

	closeDrawer(event) {
		if (this.drawerTarget.contains(event.target)) {
			return;
		}
		this.close();
	}
}
