import { Controller } from "@hotwired/stimulus";
import { DiceRoll } from "@dice-roller/rpg-dice-roller";

// Connects to data-controller="initial-income"
export default class extends Controller {
	static targets = ["initialGold"];
	static values = {
		dice: {
			type: String,
			default: "3d6",
		},
	};

	roll() {
		const roll = new DiceRoll(this.diceValue).total;
		this.initialGoldTarget.value = roll * 10;
	}
}
