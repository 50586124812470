import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="equipment-cost-controller"
export default class extends Controller {
	static targets = ["gold", "silver", "copper", "total"];

	connect() {
		this.updateFromTotal();
		this.updateToTotal();
	}

	update() {
		this.updateToTotal();
		this.updateFromTotal(); // used if someone types copper as "123", as it will convert it to 1 gold, 2 silver, 3 copper
	}

	// converts gold, silver and copper values and sums them up into the total copper amount
	updateToTotal() {
		this.totalTarget.value = Number(this.copperTarget.value) + Number(this.silverTarget.value) * 10 + Number(this.goldTarget.value) * 100;
	}

	// reads the total copper amount and updates the other values
	updateFromTotal() {
		const c = Number(this.totalTarget.value) || 0;
		this.goldTarget.value = Math.trunc(c / 100);
		this.silverTarget.value = Math.trunc((c % 100) / 10);
		this.copperTarget.value = c % 10;
	}
}
