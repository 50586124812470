import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="drawer-frame"
export default class extends Controller {
	// Close the drawer when navigating to a new page
	// Action: "turbo:load@document->drawer-frame#close"
	close() {
		if (!this.element.getAttribute("src")) return;

		this.element.removeAttribute("src");
		this.element.removeAttribute("complete");
		this.element.innerHTML = "";
	}

	// Hide modal when clicking ESC
	// Action: "keyup@window->drawer-frame#closeWithKeyboard"
	closeWithKeyboard(event) {
		if (event.code === "Escape") this.close();
	}

	// Before rendering the frame, save the window.location's URL for future comparison
	persistWindowSrc() {
		this.element.setAttribute("window-src", window.location.href);
	}

	// When we open a drawer frame, we store the URL of the page that opened it.
	// After it, when documents are loaded, if the page being navigated to isn't the same as the page that opened the drawer, close the drawer.
	closeWhenLeaving(event) {
		if (event.detail?.url && !this.isSameSrc(event.detail.url)) this.close();
	}

	isSameSrc(url) {
		try {
			return new URL(this.element.getAttribute("window-src")).pathname === new URL(url).pathname;
		} catch (e) {
			return false;
		}
	}
}
