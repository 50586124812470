import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
	static targets = ["target"];
	static classes = ["css"];

	toggle() {
		this.targetTargets.forEach((target) => {
			target.classList.toggle(...this.cssClasses);
			if (target.dataset.toggleState) {
				target.dataset.toggleState = target.dataset.toggleState === "true" ? "false" : "true";
			}
		});
	}

	show() {
		this.targetTargets.forEach((target) => {
			target.classList.add(...this.cssClasses);
			if (target.dataset.toggleState) {
				target.dataset.toggleState = target.dataset.toggleState === "true";
			}
		});
	}

	hide() {
		this.targetTargets.forEach((target) => {
			target.classList.remove(...this.cssClasses);
			if (target.dataset.toggleState) {
				target.dataset.toggleState = target.dataset.toggleState === "false";
			}
		});
	}
}
