// debounce avoids making too many requests to the server, waiting for a while in order to finally send the request
function debounce(callback, delay) {
	let timeout;

	return (...args) => {
		const context = this;
		clearTimeout(timeout);

		timeout = setTimeout(() => callback.apply(context, args), delay);
	};
}

export { debounce };
