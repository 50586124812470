import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="refresh"
export default class extends Controller {
	static targets = ["timer"];
	static values = { timer: { type: Number, default: 10 } };

	connect() {
		this.timerTargets.forEach((target) => (target.textContent = this.timerValue));

		this.start();
	}

	disconnect() {
		this.stop();
	}

	start() {
		this.timer = setInterval(() => {
			this.timerValue = Math.max(0, this.timerValue - 1); // Prevent negative values
			this.timerTargets.forEach((target) => (target.textContent = this.timerValue));

			if (this.timerValue <= 0) this.refresh();
		}, 1000);
	}

	stop() {
		clearInterval(this.timer);
	}

	refresh() {
		Turbo.visit(window.location.href);
	}
}
