import { Controller } from "@hotwired/stimulus";
import { DiceRoll, Parser } from "@dice-roller/rpg-dice-roller";

const MIN_DIE_COUNTER = 0;
const MAX_DIE_COUNTER = 1000;

// Connects to data-controller="dice-bar"
export default class extends Controller {
	static targets = [
		"dieCounter", // counter for each die such as "2" for data-die="20"
		"bonusCounter", // counter for bonus such as "-1"
		"instruction", // instruction such as "2d20+d4-1"
		"stateToggle", // mute, hide, and formula state toggles (checkboxes)
		"audio", // audio element for dice roll sound
		"linkToDrawer", // button to open the dice rolls drawer
	];

	static outlets = ["dice-toast", "dice-logger"];

	static classes = ["hidden"];

	initialize() {
		this.dice = {};
		this.bonus = 0;
		this.states = { mute: false, hide: false, formula: false };
	}

	connect() {
		this.#loadStates();

		// If there's at least one diceLoggerOutlets, hide the 'hide' stateToggle, as it's not needed
		if (this.hasDiceLoggerOutlet) {
			this.stateToggleTargets.filter((toggle) => toggle.name === "hide").forEach((toggle) => toggle.parentElement.classList.remove(...this.hiddenClasses));
		}

		this.#updateTargets();

		if (!this.application.diceBar) this.application.diceBar = this;
	}

	disconnect() {
		if (this.application.diceBar === this) delete this.application.diceBar;
	}

	addDie({ params: { die } }) {
		if (!this.dice[parseInt(die)]) this.dice[parseInt(die)] = 0;

		this.dice[parseInt(die)] = Math.min(MAX_DIE_COUNTER, this.dice[parseInt(die)] + 1);

		this.#updateTargets();
	}

	removeDie({ params: { die } }) {
		if (!this.dice[parseInt(die)]) this.dice[parseInt(die)] = 0;

		this.dice[parseInt(die)] = Math.max(MIN_DIE_COUNTER, this.dice[parseInt(die)] - 1);

		this.#updateTargets();
	}

	addBonus({ params: { bonus } }) {
		this.bonus += bonus ? parseInt(bonus) : 1;
		this.#updateTargets();
	}

	removeBonus({ params: { bonus } }) {
		this.bonus -= bonus ? parseInt(bonus) : 1;
		this.#updateTargets();
	}

	setBonus(event) {
		if (!event?.target?.value) return;

		let value = parseInt(event?.target?.value);
		if (this.bonus === value) return;

		this.bonus = value;
		this.#updateTargets();
	}

	toggleState(event) {
		let state = event.target.name;
		let value = event.target.checked;

		this.states[state] = value;
		this.#persistStates();
		this.#updateTargets();
	}

	// load states for "mute", "hide", and "formula" from localStorage as we keep it locally
	#loadStates() {
		let states = localStorage.getItem("dice-bar-states");
		if (states) this.states = JSON.parse(states);
	}

	#persistStates() {
		localStorage.setItem("dice-bar-states", JSON.stringify(this.states));
	}

	roll({ params: { instruction, success } }) {
		const formattedInstruction = this.#parseInstruction(instruction ? instruction : this.instructionTarget.value);
		if (!formattedInstruction) return; // Invalid instruction
		// If the instruction is a single negative number, don't roll. This is because it parses instructions like "-1" without a dice roll, and we don't want to roll for that.
		if (formattedInstruction.length === 1 && !isNaN(formattedInstruction[0]) && formattedInstruction[0] < 0) return;

		const diceRoll = new DiceRoll(formattedInstruction.join(""));
		if (!diceRoll.hasRolls()) return;

		if (!this.states.hide && this.hasDiceLoggerOutlet) {
			this.#createSharedRoll(diceRoll, success);
		} else {
			this.#createHiddenRoll(diceRoll, success, formattedInstruction);
		}

		if (!this.states.mute) this.#playAudio(this.#countDice(diceRoll));

		this.clear();
	}

	#createSharedRoll(diceRoll, success) {
		this.diceLoggerOutlets.forEach((diceLogger) => {
			diceLogger.create({
				params: {
					notation: diceRoll.notation,
					result: diceRoll.rolls.join(" "),
					success: success,
				},
			});
		});

		let drawerSrc = document.getElementById("drawer-right")?.src;
		// opens the drawer if it's not already open, click on the drawer link to open it
		if (this.linkToDrawerTarget && this.linkToDrawerTarget.href && (!drawerSrc || !drawerSrc.includes(this.linkToDrawerTarget.href))) {
			this.linkToDrawerTarget.click();
		}
	}

	#createHiddenRoll(diceRoll, success, formattedInstruction) {
		this.diceToastOutlets.forEach((diceToast) => {
			diceToast.create({
				params: {
					notation: diceRoll.notation,
					result: diceRoll.rolls.join(" "),
					total: diceRoll.total,
					success: this.#checkForSuccess(success, diceRoll.total),
					icon: this.#illustrateInstruction(formattedInstruction),
				},
			});
		});
	}

	clear() {
		this.dice = {};
		this.bonus = 0;

		this.#updateTargets();
	}

	// Parses and catches SyntaxError, returning "" in case it's invalid
	#parseInstruction(instruction) {
		if (instruction === "") return null;

		try {
			return Parser.parse(instruction);
		} catch (e) {
			if (e.name === "SyntaxError") return null;

			throw e;
		}
	}

	#updateTargets() {
		this.#updateDiceCounter();
		this.#updateBonusCounter();
		this.#updateInstruction();
		this.#updateStateToggles();
	}

	#updateDiceCounter() {
		this.dieCounterTargets.forEach((counter) => {
			const die = parseInt(counter.dataset.die);
			const count = this.dice[die] || 0;

			this.#setValueOrContent(counter, count === 0 ? "" : count);

			counter.dataset.diceCounterValue = count;
		});
	}

	#updateBonusCounter() {
		this.bonusCounterTargets.forEach((counter) => {
			counter.value = this.bonus;
		});
	}

	#updateInstruction() {
		this.instructionTargets.forEach((instruction) => {
			this.#setValueOrContent(instruction, this.#parseInstruction(this.#diceAndBonusString())?.join("") || "");

			if (this.states.formula) instruction.classList.remove(...this.hiddenClasses);
			else instruction.classList.add(...this.hiddenClasses);
		});
	}

	// State Toggles such as "mute", "hide", and "formula"
	#updateStateToggles() {
		this.stateToggleTargets.forEach((toggle) => {
			toggle.checked = this.states[toggle.name];
		});
	}

	#diceAndBonusString() {
		return this.#diceString() + this.#bonusString();
	}

	// Generates `2d20+1d4`
	#diceString() {
		return Object.entries(this.dice)
			.filter(([_, count]) => count > 0)
			.map(([die, count]) => `${count === 1 ? "" : count}d${die}`) // Special case for 1 so we can return "d20" instead of "1d20"
			.join("+");
	}

	#bonusString() {
		// Special case for 0 so we can return "0" instead of "+0" when we want to
		if (this.bonus === 0) return "";

		return this.bonus > 0 ? `+${this.bonus}` : this.bonus;
	}

	#setValueOrContent(target, string) {
		if ("value" in target && typeof target.value === "string") {
			target.value = string;
		} else if ("textContent" in target && typeof target.textContent === "string") {
			target.textContent = string;
		}
	}

	#checkForSuccess(conditions, total) {
		if (!conditions) return;

		const [minStr, maxStr] = conditions.split("-");
		if (!minStr || !maxStr) return;

		const min = minStr === "0" ? Number.NEGATIVE_INFINITY : parseInt(minStr),
			max = maxStr === "0" ? Number.POSITIVE_INFINITY : parseInt(maxStr);
		return total >= min && total <= max;
	}

	#countDice(ary) {
		return ary.rolls
			.map((roll) => roll?.rolls?.length)
			.filter((roll) => typeof roll === "number")
			.reduce((a, b) => a + b, 0);
	}

	// Returns the biggest dice in the instruction
	#illustrateInstruction(parsedInstruction) {
		return Math.max(...parsedInstruction.map((standardDice) => standardDice?.sides).filter((sides) => typeof sides === "number"));
	}

	// TODO: depending on the diceCount, play a different audio sample
	#playAudio(diceCount) {
		const audioSample = this.audioTargets[(Math.random() * this.audioTargets.length) | 0];
		if (!audioSample) return;

		audioSample.volume = 0.25;
		audioSample.play();
	}
}
