import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

// Connects to data-controller="tooltipper"
export default class extends Controller {
	static targets = ["element", "tooltip"];

	connect() {
		this.setupEvent();
		this.arrow = document.createElement("div");
		this.arrow.classList.add("odo-tooltip__arrow");
		this.tooltipTarget.appendChild(this.arrow);
		this.popper = createPopper(this.elementTarget, this.tooltipTarget, {
			placement: this.getPlacement(),
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 10],
					},
				},
				{
					name: "arrow",
					options: {
						element: this.arrow,
					},
				},
			],
		});
	}

	setupEvent() {
		this.trigger = this.elementTarget.getAttribute("data-tooltipper-trigger");
		const showEvents = [];
		const hideEvents = [];
		switch (this.trigger) {
			case "click":
				showEvents.push("click");
				break;
			default: // case "hover"
				showEvents.push("mouseenter");
				hideEvents.push("mouseleave");
				break;
		}

		for (const event of showEvents) {
			this.elementTarget.addEventListener(event, this.showTooltip.bind(this));
		}

		for (const event of hideEvents) {
			this.elementTarget.addEventListener(event, this.hideTooltip.bind(this));
		}
	}

	getPlacement() {
		const placement = this.tooltipTarget.getAttribute("data-tooltipper-placement");
		const placements = ["auto", "top", "right", "bottom", "left"];
		return placements.includes(placement) ? placement : "auto";
	}

	async showTooltip(event) {
		event.preventDefault();
		event.stopPropagation();
		if (this.open != undefined && this.open === true) {
			this.hideTooltip();
			return;
		}
		if (this.trigger === "click") {
			document.addEventListener("click", this.hideTooltip.bind(this), { once: true });
		}
		const state = await this.popper.update();
		switch (state.placement) {
			case "top":
				this.arrow.classList.add("-bottom-1");
				break;
			case "right":
				this.arrow.classList.add("-left-1");
				break;
			case "bottom":
				this.arrow.classList.add("-top-1");
				break;
			case "left":
				this.arrow.classList.add("-right-1");
				break;
		}
		this.tooltipTarget.classList.remove("invisible");
		this.open = true;
	}

	hideTooltip(event) {
		this.tooltipTarget.classList.add("invisible");
		this.arrow.classList.remove("-bottom-1", "-left-1", "-top-1", "-right-1");
		this.open = false;
	}
}
