import { Controller } from "@hotwired/stimulus";
import { debounce } from "../utils/debounce";

// Connects to data-controller="textarea-autogrow"
// https://www.stimulus-components.com/docs/stimulus-textarea-autogrow/ with https://transform.tools/typescript-to-javascript
export default class extends Controller {
	static values = {
		delay: {
			type: Number,
			default: 100,
		},
	};

	initialize() {
		this.autogrow = this.autogrow.bind(this);
	}

	connect() {
		this.element.style.overflow = "hidden";
		const delay = this.delayValue;

		this.onResize = delay > 0 ? debounce(this.autogrow, delay) : this.autogrow;

		this.autogrow();

		this.element.addEventListener("input", this.autogrow);
		window.addEventListener("resize", this.onResize);
	}

	disconnect() {
		window.removeEventListener("resize", this.onResize);
	}

	autogrow() {
		this.element.style.height = "auto"; // Force re-print before calculating the scrollHeight value.
		this.element.style.height = `${this.element.scrollHeight}px`;
	}
}
