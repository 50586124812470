import { Controller } from "@hotwired/stimulus";
import { DiceRoll } from "@dice-roller/rpg-dice-roller";

// Connects to data-controller="attribute-random-roller"
export default class extends Controller {
	static targets = ["attribute"];
	static values = {
		dice: {
			type: String,
			default: "3d6",
		},
	};
	static outlets = ["attribute-modifier"];

	connect() {
		// We skip modifiers when loading the page (first time load) due to outlets not being ready yet
		this.roll({ params: { skipModifiers: true } });
	}

	roll(event) {
		this.attributeTargets.forEach((attribute) => {
			attribute.value = new DiceRoll(this.diceValue).total;
		});

		if (event?.params?.skipModifiers !== true) {
			this.calculateModifiers();
		}
	}

	// Recalculate all attribute modifiers
	calculateModifiers() {
		this.attributeModifierOutlets.forEach((outlet) => {
			outlet.calculate();
		});
	}

	reset(event) {
		this.attributeTargets.forEach((attribute) => {
			attribute.value = "";
		});

		this.roll();
	}
}
