import { Controller } from "@hotwired/stimulus";
import { debounce } from "../utils/debounce";

// Connects to data-controller="autosubmit"
export default class extends Controller {
	static values = {
		delay: { type: Number, default: 1000 },
	};

	connect() {
		this.submit = this.submit.bind(this);
	}

	submit() {
		this.submit = debounce(() => this.immediateSubmit(), this.delayValue);
	}

	immediateSubmit() {
		return this.element.requestSubmit();
	}
}
