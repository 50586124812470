import { Controller } from "@hotwired/stimulus";

import Sortable from "sortablejs";

// Implements https://sortablejs.github.io/Sortable/
export default class extends Controller {
	static classes = ["drag", "ghost", "chosen", "filtered", "handle"];
	static values = {
		animation: { type: Number, default: 150 },
	};
	static outlets = ["autosubmit"];

	connect() {
		let options = { onEnd: this.end.bind(this) };

		if (this.hasHandleClass) options.handle = `.${this.handleClass}`;
		if (this.hasFilteredClass) options.filter = `.${this.filteredClass}`;
		if (this.hasDragClass) options.dragClass = this.dragClass;
		if (this.hasGhostClass) options.ghostClass = this.ghostClass;
		if (this.hasChosenClass) options.chosenClass = this.chosenClass;

		this.sortable = new Sortable(this.element, options);
	}

	disconnect() {
		this.sortable.destroy();
		this.sortable = undefined;
	}

	end(event) {
		this.autosubmitOutlets.forEach((autosubmit) => autosubmit.immediateSubmit());
	}
}
