import { Controller } from "@hotwired/stimulus";

import EasyMDE from "easymde";

export default class extends Controller {
	connect() {
		let options = {
			element: this.element,
			autoDownloadFontAwesome: false,
			spellChecker: false,
			blockStyles: { italic: "_" },
			indentWithTabs: false,
			previewClass: ["odo-markdown", "editor-preview"],
			sideBySideFullscreen: false,
			toolbar: [
				{ name: "bold", action: EasyMDE.toggleBold, title: "Negrito", className: "fa fa-bold" },
				{ name: "italic", action: EasyMDE.toggleItalic, title: "Itálico", className: "fa fa-italic" },
				{ name: "strikethrough", action: EasyMDE.toggleStrikethrough, title: "Risco", className: "fa fa-strikethrough" },
				"|",
				{ name: "heading-bigger", action: EasyMDE.toggleHeadingBigger, title: "Cabeçalho Maior", className: "fa fa-lg fa-header" },
				{ name: "heading-1", action: EasyMDE.toggleHeading1, title: "Cabeçalho Maior", className: "fa fa-header header-1" },
				{ name: "heading-2", action: EasyMDE.toggleHeading2, title: "Cabeçalho Médio", className: "fa fa-header header-2" },
				{ name: "heading-3", action: EasyMDE.toggleHeading3, title: "Cabeçalho Pequeno", className: "fa fa-header header-3" },
				{ name: "heading-smaller", action: EasyMDE.toggleHeadingSmaller, title: "Cabeçalho Menor", className: "fa fa-header" },
				"|",
				{ name: "quote", action: EasyMDE.toggleBlockquote, title: "Citação", className: "fa fa-quote-left" },
				{ name: "unordered-list", action: EasyMDE.toggleUnorderedList, title: "Lista Não Ordenada", className: "fa fa-list-ul" },
				{ name: "ordered-list", action: EasyMDE.toggleOrderedList, title: "Lista Numerada", className: "fa fa-list-ol" },
				// "|",
				// { name: "link", action: EasyMDE.drawLink, title: "Adicionar Link", className: "fa fa-link" },
				// { name: "image", action: EasyMDE.drawImage, title: "Adicionar Imagem", className: "fa fa-image" },
				// { name: "upload-image", action: EasyMDE.drawUploadedImage, title: "Anexar Imagem", className: "fa fa-image" },
				"|",
				{ name: "table", action: EasyMDE.drawTable, title: "Inserir Tabela", className: "fa fa-table" },
				{ name: "horizontal-rule", action: EasyMDE.drawHorizontalRule, title: "Inserir Linha Horizontal", className: "fa fa-minus" },
				"|",
				{ name: "preview", action: EasyMDE.togglePreview, title: "Pré-Visualização", className: "fa fa-eye no-disable" },
				{ name: "side-by-side", action: EasyMDE.toggleSideBySide, title: "Ativar Lado a Lado", className: "fa fa-columns no-disable no-mobile" },
				{ name: "fullscreen", action: EasyMDE.toggleFullScreen, title: "Ativar Tela Cheia", className: "fa fa-arrows-alt no-disable no-mobile" },
				"|",
				{ name: "guide", action: "https://ajuda.gitlab.io/guia-rapido/markdown/markdown/", title: "Guia", className: "fa fa-question-circle" },
			],
		};
		this.easymde = new EasyMDE(options);
	}

	disconnect() {
		this.easymde.toTextArea();
		this.easymde = undefined;
	}
}
