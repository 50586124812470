import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modall"
export default class extends Controller {
	static targets = ["modal"];

	openModal() {
		this.modalTarget.classList.remove("invisible");
		this.modalTarget.classList.add("z-40");
		this.modalTarget.addEventListener("mousedown", this.handleModalClick);
	}

	handleModalClick = (event) => {
		if (event.target != event.currentTarget) {
			return;
		}
		this.closeModal();
	};

	closeModal() {
		this.modalTarget.classList.add("invisible");
		this.modalTarget.classList.remove("z-40");
		this.modalTarget.removeEventListener("mousedown", this.handleModalClick);
	}
}
