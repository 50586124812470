import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="experience-points"
export default class extends Controller {
	static targets = ["current", "levelUpAction"];
	static values = {
		nextLevelMinimum: {
			type: Number,
			default: 0,
		},
	};

	connect() {
		this.update();
	}

	update() {
		if (!this.hasCurrentTarget) return;

		const current = parseInt(this.currentTarget.value);
		if (!Number.isInteger(current)) return;

		if (current >= this.nextLevelMinimumValue) {
			this.levelUpActionTargets.forEach((element) => {
				element.classList.remove("invisible");
			});
		} else {
			this.levelUpActionTargets.forEach((element) => {
				element.classList.add("invisible");
			});
		}
	}
}
