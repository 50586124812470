import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dice-toast"
export default class extends Controller {
	static targets = ["template", "list"];
	static classes = ["success", "failure"];
	static values = {
		maxItems: {
			type: Number,
			default: 4,
		},
	};

	create({ params: { notation, result, total, success, icon } }) {
		const template = this.templateTarget.content.cloneNode(true);
		template.querySelector("[data-dice-toast-target='notation']").textContent = notation.length > 20 ? notation.substring(0, 20) + "…" : notation;
		template.querySelector("[data-dice-toast-target='notation']").title = notation;
		template.querySelector("[data-dice-toast-target='result']").textContent = result.length > 20 ? result.substring(0, 20) + "…" : result;
		template.querySelector("[data-dice-toast-target='result']").title = result;
		template.querySelector("[data-dice-toast-target='total']").textContent = total;

		template.querySelectorAll(`[data-dice-toast-target='icon'][data-dice-toast-icon-value='${icon}']`).forEach((icon) => icon.classList.remove("hidden"));

		if (success != undefined) template.children[0]?.classList.add(...(success ? this.successClasses : this.failureClasses));
		this.listTargets.forEach((list) => list.appendChild(template));

		this.#trimList();
	}

	clear() {
		this.listTargets.forEach((list) => (list.innerHTML = ""));
	}

	// Trim the list to a maximum of `maxItems`, keeping only the last `maxItems` items
	#trimList() {
		this.listTargets.forEach((list) => {
			const items = list.children;
			if (items.length <= this.maxItemsValue) return;

			for (let i = 0; i < items.length - this.maxItemsValue; i++) {
				items[i].remove();
			}
		});
	}
}
