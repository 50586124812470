import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="order"
export default class extends Controller {
	static targets = ["arrow", "form"];
	static classes = ["unselected", "selected"];
	static values = {
		selection: { type: String, default: "" },
	};
	static outlets = ["autosubmit"];

	connect() {
		this.updateUI();
	}

	toggle(event) {
		const key = event.params.key;

		if (this.selectionValue === key) {
			this.selectionValue = `reverse_${key}`;
		} else {
			this.selectionValue = key;
		}

		this.updateUI();
		this.updateForm();
	}

	updateUI() {
		this.arrowTargets.forEach((item) => {
			if (this.selectionValue == item.dataset.key) {
				item.classList.add(...this.selectedClasses);
				item.classList.remove(...this.unselectedClasses);
			} else {
				item.classList.remove(...this.selectedClasses);
				item.classList.add(...this.unselectedClasses);
			}
		});
	}

	updateForm() {
		this.formTarget.replaceChildren();

		if (this.selectionValue !== "")
			this.formTarget.insertAdjacentHTML("beforeend", `<input name="order" value="${this.selectionValue}" type="hidden" class="hidden">`);

		this.autosubmitOutlets.forEach((autosubmit) => autosubmit.immediateSubmit());
	}
}
