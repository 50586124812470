import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="resource-uses"
export default class extends Controller {
	static targets = ["checkbox", "markable"];
	static classes = ["used"];

	connect() {
		this.update();
	}

	update() {
		if (this.checkboxTargets.length === 0) {
			return;
		}

		// this.titleTarget.classList.add(...this.usedClasses, this.isAllChecked());
		if (this.isAllChecked()) {
			this.markableTargets.forEach((markable) => {
				markable.classList.add(...this.usedClasses);
			});
		} else {
			this.markableTargets.forEach((markable) => {
				markable.classList.remove(...this.usedClasses);
			});
		}
	}

	isAllChecked() {
		return !this.checkboxTargets.some((checkbox) => !checkbox.checked);
	}
}
