import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["gpAmount", "spAmount", "cpAmount"];
	static outlets = ["autosubmit"];

	convert() {
		let gold = Number(this.gpAmountTarget.value);
		let silver = Number(this.spAmountTarget.value);
		let copper = Number(this.cpAmountTarget.value);

		silver += Math.trunc(copper / 10);
		copper = copper % 10;
		gold += Math.trunc(silver / 10);
		silver = silver % 10;

		if (Number(this.gpAmountTarget.value) != gold || Number(this.spAmountTarget.value) != silver || Number(this.cpAmountTarget.value) != copper) {
			if (window.confirm(`Converter para ${gold} Peças de Ouro, ${silver} Peças de Prata e ${copper} Peças de Cobre?`)) {
				this.cpAmountTarget.value = copper;
				this.spAmountTarget.value = silver;
				this.gpAmountTarget.value = gold;
				this.autosubmitOutlet.immediateSubmit();
			}
		}
	}
}
