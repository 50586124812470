import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="raffle-broadcast"
// Calls multiple other rafflers to raffle
export default class extends Controller {
	static outlets = ["raffle", "raffle-background"];

	pick() {
		this.raffleOutlets.forEach((raffle) => {
			raffle.pick();
		});

		this.raffleBackgroundOutlets.forEach((raffle) => {
			raffle.pick();
		});
	}
}
