import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="health-points"
export default class extends Controller {
	static targets = ["input", "klassBonus", "modCon", "levelTotal", "extra", "total"];

	connect() {
		const levels = this.inputTargets.map((inputTarget) => this.getLevel(inputTarget));
		levels.forEach((level) => {
			this.updateLevelTotal(level);
		});

		this.updateTotal();
	}

	getLevel(target) {
		return target.getAttribute("data-level");
	}

	getTargetByLevel(targets, level) {
		return targets.find((i) => {
			return this.getLevel(i) == level;
		});
	}

	getTargetsByLevel(level) {
		const inputTarget = this.getTargetByLevel(this.inputTargets, level);
		const klassBonusTarget = this.getTargetByLevel(this.klassBonusTargets, level);
		const modConTarget = this.getTargetByLevel(this.modConTargets, level);
		const levelTotalTarget = this.getTargetByLevel(this.levelTotalTargets, level);
		return { inputTarget, klassBonusTarget, modConTarget, levelTotalTarget };
	}

	getValuesFromTargets(inputTarget, klassBonusTarget, modConTarget, levelTotalTarget) {
		return {
			base_hp: Number(inputTarget.value),
			klass_bonus: klassBonusTarget != undefined ? Number(klassBonusTarget.value) : 0,
			mod_con: Number(modConTarget.value),
			level_total: Number(levelTotalTarget.getAttribute("value")),
		};
	}

	calculateLevelTotal(base_hp, klass_bonus, mod_con) {
		return base_hp + klass_bonus + mod_con;
	}

	calculateTotal(total, oldLevelTotal, newLevelTotal, extra = 0) {
		return total - oldLevelTotal + newLevelTotal + extra;
	}

	updateInnerTextTarget(target, value) {
		target.innerText = value;
	}

	updateAttrValueTarget(target, value) {
		target.setAttribute("value", value);
	}

	updateLevelTotal(level) {
		const { inputTarget, klassBonusTarget, modConTarget, levelTotalTarget } = this.getTargetsByLevel(level);
		const levelValues = this.getValuesFromTargets(inputTarget, klassBonusTarget, modConTarget, levelTotalTarget);
		const levelTotal = this.calculateLevelTotal(levelValues.base_hp, levelValues.klass_bonus, levelValues.mod_con);
		this.updateInnerTextTarget(levelTotalTarget, levelTotal);
		this.updateAttrValueTarget(levelTotalTarget, levelTotal);
	}

	updateTotal() {
		const totalTarget = this.totalTarget;
		const levels = this.levelTotalTargets;
		const extraValue = Number(this.extraTarget.value);

		this.totalValue = levels.reduce((total, levelTarget) => {
			return total + Number(levelTarget.getAttribute("value"));
		}, 0);

		this.totalValue += extraValue;

		this.updateInnerTextTarget(totalTarget, this.totalValue);
		this.updateAttrValueTarget(totalTarget, this.totalValue);
	}

	update(event) {
		const level = this.getLevel(event.target);

		this.updateLevelTotal(level);
		this.updateTotal();
	}
}
