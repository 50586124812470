import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="raffle"
// Used for raffling simple options from a list
export default class extends Controller {
	static targets = ["output"];
	static values = { options: Array, rareOptions: Array };

	pick(event) {
		if (this.hasRareOptionsValue && this.randomIndex(this.optionsValue.length + 1) === 0) {
			this.rarePick(event);
			return;
		}

		this.outputTarget.value = this.optionsValue[this.randomIndex(this.optionsValue.length)];
	}

	rarePick(event) {
		this.outputTarget.value = this.rareOptionsValue[this.randomIndex(this.rareOptionsValue.length)];
	}

	randomIndex(length) {
		return Math.floor(Math.random() * length);
	}
}
