import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attribute-reset"
export default class extends Controller {
	static targets = ["select", "attribute"];
	static outlets = ["autosubmit"];

	reset(event) {
		this.attributeTargets.forEach((attribute) => {
			attribute.value = "";
		});

		if (event.params.method !== false) {
			this.selectTargets.forEach((select) => {
				select.value = "";
			});
		}

		this.autosubmitOutlets.forEach((autosubmit) => autosubmit.immediateSubmit());
	}
}
