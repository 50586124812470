import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="portraits"
export default class extends Controller {
	static targets = ["file", "preview", "purge", "purgeButton", "galleryPortrait"];

	connect() {
		this.fileTarget.onchange = this.loadFile.bind(this);
		this.#checkForPurge();
	}

	// Handler for when a file is selected, so we can preview them and check for purging
	loadFile(event) {
		// If no files were selected
		if (event.target.files.length === 0) {
			return;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.previewTarget.src = reader.result;
		};
		reader.readAsDataURL(event.target.files[0]);
		this.current_file = event.target.files;

		this.#uncheckGalleryPortraits();

		// Uncheck the purge checkbox and show the purge button
		this.purgeTarget.checked = false;
		this.purgeButtonTarget.classList.remove("invisible");
	}

	// Open the file upload prompt
	fileSelector() {
		// Open upload prompt
		this.fileTarget.click();
	}

	galleryPreview(event) {
		this.fileTarget.value = "";
		this.current_file = null;
		this.previewTarget.src = event.params.imageSource;

		this.#checkForPurge();
	}

	purge() {
		this.#uncheckGalleryPortraits();

		// Remove upload if any
		this.fileTarget.value = "";

		// Reset preview
		this.previewTarget.src = this.previewTarget.dataset.portraitsEmptyImageSource;

		// Check the purge checkbox and hide the purge button
		this.purgeTarget.checked = true;
		this.purgeButtonTarget.classList.add("invisible");
	}

	// Uncheck all gallery portraits
	#uncheckGalleryPortraits() {
		this.galleryPortraitTargets.forEach((el) => (el.checked = false));
	}

	// Check if the image is empty, and if so, check the purge checkbox and hide the purge button
	#checkForPurge() {
		if (this.previewTarget.src == this.previewTarget.dataset.portraitsEmptyImageSource) {
			this.purge();
		} else {
			this.purgeTarget.checked = false;
			this.purgeButtonTarget.classList.remove("invisible");
		}
	}
}
