import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="raffle-background"
// Used for raffling background options
export default class extends Controller {
	static targets = ["output"];
	static values = {
		structureOptions: Array,
		locationOptions: Array,
		motiveOptions: Array,
		familyOptions: Array,
		tragedyOptions: Array,
		otherRaceOptions: Array,
		klass: String,
		race: String,
	};

	pick(event) {
		const structure = this.structureOptionsValue[this.randomIndex(this.structureOptionsValue.length)];
		const location = this.locationOptionsValue[this.randomIndex(this.locationOptionsValue.length)];
		const otherLocation = this.locationOptionsValue[this.randomIndex(this.locationOptionsValue.length)];
		const motive = this.motiveOptionsValue[this.randomIndex(this.motiveOptionsValue.length)];
		const family = this.familyOptionsValue[this.randomIndex(this.familyOptionsValue.length)];
		const tragedy = this.tragedyOptionsValue[this.randomIndex(this.tragedyOptionsValue.length)];
		const otherRace = this.otherRaceOptionsValue[this.randomIndex(this.otherRaceOptionsValue.length)];
		const klass = this.klassValue;
		const race = this.raceValue;

		this.outputTarget.value = structure
			.replace("$location", location)
			.replace("$otherLocation", otherLocation)
			.replace("$motive", motive)
			.replace("$family", family)
			.replace("$tragedy", tragedy)
			.replace("$otherRace", otherRace)
			.replace("$klass", klass)
			.replace("$race", race);
	}

	randomIndex(length) {
		return Math.floor(Math.random() * length);
	}
}
