import { Controller } from "@hotwired/stimulus";
import { DiceRoll } from "@dice-roller/rpg-dice-roller";

// Connects to data-controller="attribute-select-roller"
export default class extends Controller {
	static targets = ["attribute", "rollsText"];
	static values = {
		dice: {
			type: String,
			default: "3d6",
		},
		rolls: {
			type: Array,
			default: [],
		},
	};
	static outlets = ["attribute-modifier"];

	connect() {
		this.roll();
	}

	roll(event) {
		if (this.rollsValue.length === 0) {
			this.rollsValue = [
				new DiceRoll(this.diceValue).total,
				new DiceRoll(this.diceValue).total,
				new DiceRoll(this.diceValue).total,
				new DiceRoll(this.diceValue).total,
				new DiceRoll(this.diceValue).total,
				new DiceRoll(this.diceValue).total,
			].sort((a, b) => b - a);
		}

		this.rollsTextTarget.innerText = this.rollsValue.join(", ");

		this.updateOptions();
	}

	reset(event) {
		this.attributeTargets.forEach((attribute) => {
			attribute.value = "";
		});

		this.rollsValue = [];

		this.roll();

		this.calculateModifiers();
	}

	updateOptions() {
		const allRolls = this.rollsValue.map((roll) => roll.toString());
		const selectedRolls = this.attributeTargets.map((attribute) => attribute.value.toString()).filter((value) => value != "");

		const unselectedRolls = this.difference(allRolls, selectedRolls);

		this.attributeTargets.forEach((attribute) => {
			const selectedValue = attribute.value;

			attribute.replaceChildren();

			attribute.insertAdjacentHTML("beforeend", `<option value>—</option>`);
			if (selectedValue != "") {
				attribute.insertAdjacentHTML("beforeend", `<option value="${selectedValue}">${selectedValue}</option>`);
			}

			unselectedRolls.forEach((roll) => {
				attribute.insertAdjacentHTML("beforeend", `<option value="${roll}">${roll}</option>`);
			});
			attribute.value = selectedValue;
		});
	}

	// Recalculate all attribute modifiers
	calculateModifiers() {
		this.attributeModifierOutlets.forEach((outlet) => {
			outlet.calculate();
		});
	}

	difference(a, b) {
		return a.filter(
			function (v) {
				return !this.get(v) || !this.set(v, this.get(v) - 1);
			},
			b.reduce((acc, v) => acc.set(v, (acc.get(v) || 0) + 1), new Map()),
		);
	}
}
