import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dice-logger"
export default class extends Controller {
	static targets = ["notation", "result", "success"];

	create({ params: { notation, result, success } }) {
		this.notationTargets.forEach((t) => (t.value = notation));
		this.resultTargets.forEach((t) => (t.value = result));
		this.successTargets.forEach((t) => (t.value = success ? success : ""));

		this.element.requestSubmit();

		this.cleanup();
	}

	cleanup() {
		this.notationTargets.forEach((t) => (t.value = ""));
		this.resultTargets.forEach((t) => (t.value = ""));
		this.successTargets.forEach((t) => (t.value = ""));
	}
}
