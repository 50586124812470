import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="spells-builder"
export default class extends Controller {
	static targets = ["slotsDisplay", "spellbookCheckbox", "memorizedSelect"];

	static values = {
		// Available slots for each circle level, where the index (plus one) is the circle level and the value is the number of slots
		// e.g. [3, 2, 2, 1] for 3 on 1st, 2 on 2nd, 2 on 3rd, 1 on 4th
		slots: Array,
	};

	static classes = ["overlimit", "underlimit", "filled", "noAccess"];

	connect() {
		this.update();
	}

	// Updates the display of memorized spells
	update(event) {
		if (event && event.target) {
			this.checkReverse(event.target);
		}
		this.updateSelects();
		this.updateDisplays();
	}

	// Check reverse spells (like checking 'Abrir' enables 'Trancar')
	checkReverse(element) {
		if (element.dataset.reverseId && element.dataset.reverseId !== "") {
			let reverseElement = document.getElementById(element.dataset.reverseId);
			if (reverseElement) {
				reverseElement.checked = element.checked;
			}
		}
	}

	// If there are "G" (grimório) checkboxes, enable/disable "M" (memorizadas) as needed
	updateSelects() {
		this.spellbookCheckboxTargets.forEach((checkbox) => {
			let controlledSelect = document.getElementById(checkbox.dataset.controlledSelectId);
			if (controlledSelect) {
				if (checkbox.checked) {
					controlledSelect.disabled = false;
				} else {
					controlledSelect.disabled = true;
					controlledSelect.value = 0;
				}
			}
		});
	}

	// Update text and color such as "Memorizadas 2 magias de 3 disponíveis"
	updateDisplays() {
		let memorized = this.memorized();

		this.slotsDisplayTargets.forEach((display) => {
			let index = display.dataset.circle - 1;
			let m = memorized[index] || 0;
			let s = this.slotsValue[index] || 0;

			this.underlimitClasses.forEach((c) => display.classList.remove(c));
			this.overlimitClasses.forEach((c) => display.classList.remove(c));
			this.filledClasses.forEach((c) => display.classList.remove(c));
			this.noAccessClasses.forEach((c) => display.classList.remove(c));

			if (m === 0 && s === 0) {
				this.noAccessClasses.forEach((c) => display.classList.add(c));
				display.innerHTML = "Sem acesso a este círculo de magia";
			} else {
				let memorizedText = m === 1 ? "Memorizada: <strong>1</strong> magia" : `Memorizadas: <strong>${m}</strong> magias`;
				let slotsText = s === 1 ? "de <strong>1</strong> disponível" : `de <strong>${s}</strong> disponíveis`;
				display.innerHTML = `${memorizedText} ${slotsText}`;
				if (m > s) {
					this.overlimitClasses.forEach((c) => display.classList.add(c));
				} else if (m < s) {
					this.underlimitClasses.forEach((c) => display.classList.add(c));
				} else {
					this.filledClasses.forEach((c) => display.classList.add(c));
				}
			}
		});
	}

	// Calculates memorized spells
	memorized() {
		let memorizedValue = [];

		this.memorizedSelectTargets.forEach((select) => {
			let index = select.dataset.circle - 1;

			// If memorizedValue is not defined, set it, otherwise sum it
			if (memorizedValue[index] === undefined) {
				memorizedValue[index] = parseInt(select.value);
			} else {
				memorizedValue[index] += parseInt(select.value);
			}
		});

		return memorizedValue;
	}
}
