import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="equipment-buy-controller"
export default class extends Controller {
	static targets = ["quantity", "button", "gold", "silver", "copper", "total"];
	static values = {
		packCount: Number, // how many items are in a pack (usually 1)
		packCost: Number, // how much is the pack sold for (e.g. "tochas" are sold in packs of 5 for 1PO)
		funds: Number,
	};

	connect() {
		this.updateFromTotal();
		this.updateToTotal();
		this.updateQuantity();
	}

	buttonToggle() {
		Number(this.totalTarget.value) > Number(this.fundsValue) ? this.disableButton() : this.enableButton();
	}

	disableButton() {
		this.buttonTarget.disabled = true;
		this.buttonTarget.classList.remove("odo-button__primary");
		this.buttonTarget.classList.add("odo-button__disabled");
	}

	enableButton() {
		this.buttonTarget.disabled = false;
		this.buttonTarget.classList.remove("odo-button__disabled");
		this.buttonTarget.classList.add("odo-button__primary");
	}

	// Notice that when we update the quantity, we ignore the existing total value and reset based on the original unitary cost
	updateQuantity() {
		this.totalTarget.value = this.totalAmount();
		this.updateFromTotal();
		this.buttonToggle();
	}

	updateCost() {
		this.updateToTotal();
		this.buttonToggle();
	}

	// converts gold, silver and copper values and sums them up into the total copper amount
	updateToTotal() {
		this.totalTarget.value = Number(this.copperTarget.value) + Number(this.silverTarget.value) * 10 + Number(this.goldTarget.value) * 100;
	}

	// reads the total copper amount and updates the other values
	updateFromTotal() {
		const c = Number(this.totalTarget.value) || 0;
		this.goldTarget.value = Math.trunc(c / 100);
		this.silverTarget.value = Math.trunc((c % 100) / 10);
		this.copperTarget.value = c % 10;
	}

	// We calculate the total amount based on the unitary cost and the quantity, and round it up to the nearest integer due to items like "vela" and "flecha de caça"
	totalAmount() {
		return Math.ceil(this.unitaryCost() * Number(this.quantityTarget.value));
	}

	unitaryCost() {
		return this.packCostValue / this.packCountValue;
	}
}
