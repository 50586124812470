import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="rogue-talents"
export default class extends Controller {
	static targets = ["select", "score", "scoreRender"];
	static values = {
		talents: {
			type: Array,
			default: [],
		},
	};

	maxScore = 5;

	connect() {
		this.updateAllOptions();
	}

	getScoreFromTalent(talent) {
		return this.scoreTargets.find((target) => {
			return target.name == `${talent}-score`;
		}).value;
	}

	getSelectsFromLevel(target) {
		const characterLevel = target.getAttribute("data-character-level");
		const selectsOnLevel = this.selectTargets.filter((target) => {
			return target.getAttribute("data-character-level") == characterLevel;
		});

		return selectsOnLevel;
	}

	getTalentsFromSelects(selects) {
		const allTalents = this.talentsValue.map((talent) => ({
			title: talent[0],
			key: talent[1],
		}));

		const selectedTalents = selects.map((select) => select.getAttribute("value")).filter((value) => value != "");
		const unselectedTalents = allTalents.filter((talent) => {
			return !selectedTalents.includes(talent.key) && this.getScoreFromTalent(talent.key) < this.maxScore;
		});

		return { allTalents, selectedTalents, unselectedTalents };
	}

	setSelectedOption(selects) {
		selects.forEach((select) => {
			const selectedOption = select.options[select.selectedIndex];
			if (selectedOption) {
				select.setAttribute("value", selectedOption.value);
			}
		});
	}

	update(event) {
		const currentLevel = event.target.getAttribute("data-character-level");
		const selectsOnLevel = this.getSelectsFromLevel(event.target);
		this.updateScore(false, event.target);
		this.setSelectedOption(selectsOnLevel);
		this.updateScore(true, event.target);
		this.updateOptions(selectsOnLevel);
		this.updateAllOptions(currentLevel);
	}

	updateInputScore(talent, value) {
		const score = this.scoreTargets.find((target) => {
			return target.name == `${talent}-score`;
		});
		score.setAttribute("value", value);
	}

	updateScoreRender(talent, value) {
		const score = this.scoreRenderTargets.find((target) => {
			return target.getAttribute("data-name") == `${talent}-score`;
		});
		score.innerText = value;
	}

	updateScore(shouldIncrement, target) {
		const selectedTalent = target.getAttribute("value");
		if (selectedTalent) {
			const currentScore = this.getScoreFromTalent(selectedTalent);
			let value = currentScore;
			if (shouldIncrement) {
				value = Number(value) + 1;
			} else {
				value = Number(value) - 1;
			}
			this.updateInputScore(selectedTalent, value);
			this.updateScoreRender(selectedTalent, value);
		}
	}

	updateAllOptions(ignoreLevel) {
		const levels = this.selectTargets.map((select) => {
			return select.getAttribute("data-character-level");
		});

		const unique = [...new Set(levels)];
		if (ignoreLevel) {
			if (unique.indexOf(ignoreLevel) !== -1) {
				unique.splice(ignoreLevel, 1);
			}
		}

		this.selectTargets.forEach((select) => {
			if (unique.length === 0) {
				return;
			}
			if (unique[0] === select.getAttribute("data-character-level")) {
				const selectsOnLevel = this.getSelectsFromLevel(select);
				this.setSelectedOption(selectsOnLevel);
				this.updateOptions(selectsOnLevel);
				unique.shift();
			}
		});
	}

	updateOptions(selectsOnLevel) {
		const { allTalents, unselectedTalents } = this.getTalentsFromSelects(selectsOnLevel);

		selectsOnLevel.forEach((select) => {
			let selectedTitle = "";
			let selectedKey = "";

			const currentSelectValue = select.getAttribute("value");
			const currentTalent = allTalents.find((talent) => talent.key === currentSelectValue);

			if (currentTalent) {
				selectedTitle = currentTalent.title;
				selectedKey = currentTalent.key;
			}

			const selectedOption = select.options[select.selectedIndex];
			if (selectedOption) {
				selectedTitle = selectedOption.label;
				selectedKey = selectedOption.value;
			}

			select.replaceChildren();

			select.insertAdjacentHTML("beforeend", `<option value>Selecione um talento</option>`);
			if (selectedKey != "") {
				select.insertAdjacentHTML("beforeend", `<option value="${selectedKey}" selected>${selectedTitle}</option>`);
			}

			unselectedTalents.forEach((talent) => {
				select.insertAdjacentHTML("beforeend", `<option value="${talent.key}">${talent.title}</option>`);
			});
			select.setAttribute("value", selectedKey);
		});
	}
}
