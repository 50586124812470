import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordion"
export default class extends Controller {
	static targets = ["icon", "title", "body"];
	static values = { open: Boolean };

	connect() {
		this.load();
	}

	toggle(event) {
		if (this.isIgnored(event.target)) return;

		this.openValue = !this.openValue;
		this.load(event);
	}

	load(event) {
		if (this.openValue) this.open(event);
		else this.close(event);
	}

	open(event) {
		this.iconTargets.forEach((icon) => {
			icon.classList.add("rotate-180");
		});
		this.titleTargets.forEach((title) => {});
		this.bodyTargets.forEach((body) => {
			body.classList.remove("hidden");
		});
		this.openValue = true;
	}

	close(event) {
		this.iconTargets.forEach((icon) => {
			icon.classList.remove("rotate-180");
		});
		this.titleTargets.forEach((title) => {});
		this.bodyTargets.forEach((body) => {
			body.classList.add("hidden");
		});
		this.openValue = false;
	}

	// Ignores up to 3 levels of parents if they have the data-accordion-ignore attribute
	isIgnored(target) {
		return (
			target?.dataset?.accordionIgnore ||
			target?.parentElement?.dataset?.accordionIgnore ||
			target?.parentElement?.parentElement?.dataset?.accordionIgnore ||
			target?.parentElement?.parentElement?.parentElement?.dataset?.accordionIgnore
		);
	}
}
