console.log("Old Dragon 🐲");

// If using a TypeScript entrypoint file:
//   <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

import "@hotwired/turbo-rails";
import "@rails/activestorage";

import "./activestorage.js";
import "./turbopower.js";

// Application
import "../controllers/index.js";
import "../channels/index.js";
