import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="mobile-search"
export default class extends Controller {
	static targets = ["searchDiv"];

	connect() {
		if (!this.searchDivTarget.classList.contains("hidden")) {
			this.searchDivTarget.classList.add("hidden");
		}
	}

	toggleSearch() {
		this.searchDivTarget.classList.toggle("hidden");
		if (!this.searchDivTarget.classList.contains("hidden")) {
			document.addEventListener("click", this.hideSearchOutside);
		} else {
			document.removeEventListener("click", this.hideSearchOutside);
		}
	}

	hideSearchOutside = (event) => {
		if (!this.element.contains(event.target)) {
			this.searchDivTarget.classList.add("hidden");
			document.removeEventListener("click", this.hideSearchOutside);
		}
	};
}
