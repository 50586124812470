import { Controller } from "@hotwired/stimulus";
import { DiceRoll } from "@dice-roller/rpg-dice-roller";

// Connects to data-controller="dice"
export default class extends Controller {
	static targets = ["result"];
	static values = {
		dice: {
			type: String,
			default: "3d6",
		},
		auto: {
			type: Boolean,
			default: true,
		},
	};

	connect() {
		if (this.resultTarget.value === "" && this.autoValue !== false) this.roll();
	}

	roll() {
		const result = new DiceRoll(this.diceValue).total;
		this.resultTarget.value = result;
		this.resultTarget.dispatchEvent(new Event("input", { bubbles: true }));
		this.dispatch("rolled", { detail: { roll: result } });
	}
}
