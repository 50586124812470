import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="chapter-map"
export default class extends Controller {
	static targets = ["image"];
	static values = {
		masterButtonText: { type: String, default: "Ver mapa COM legenda" },
		playerButtonText: { type: String, default: "Ver mapa SEM legenda" },
	};
	static classes = ["button"];

	// chapter maps should always have src with the master version set.
	connect() {
		if (this.imageTarget.dataset.masterVersion) {
			this.imageTarget.src = this.imageTarget.dataset.masterVersion;
		} else {
			this.imageTarget.dataset.masterVersion = this.imageTarget.src;
		}

		// Remove all buttons from this element, in case user is returning and disconnect wasn't properly called
		this.element.querySelectorAll("button").forEach((button) => button.remove());

		this.createToggleButton();
	}

	disconnect() {
		this.button.remove();
	}

	createToggleButton() {
		this.button = document.createElement("button");
		this.button.textContent = this.playerButtonTextValue;
		this.button.title = "Alterna entre a versão do Mestre e do Jogador";
		this.button.classList.add(...this.buttonClasses);
		this.button.addEventListener("click", this.toggleImage.bind(this));

		this.element.appendChild(this.button);
	}

	isOnMasterVersion() {
		return this.imageTarget.getAttribute("src") === this.imageTarget.dataset.masterVersion;
	}

	toggleImage() {
		if (this.isOnMasterVersion()) {
			this.imageTarget.src = this.imageTarget.dataset.playerVersion;
			this.button.textContent = this.masterButtonTextValue;
		} else {
			this.imageTarget.src = this.imageTarget.dataset.masterVersion;
			this.button.textContent = this.playerButtonTextValue;
		}
	}
}
