import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calculator"
export default class extends Controller {
	static targets = ["bonus", "current", "diff"];

	add() {
		this.calculateAndSubmit(1);
	}

	subtract() {
		this.calculateAndSubmit(-1);
	}

	calculateAndSubmit(sign) {
		const diff = parseInt(this.diffTarget.value);
		const current = parseInt(this.currentTarget.value);

		this.diffTarget.value = "";
		this.currentTarget.value = this.clamp(this.calculate(current, diff, sign), this.currentTarget.max);
		this.dispatch("calculate");
	}

	calculate(current, diff, sign) {
		if (!Number.isInteger(diff)) return current;

		let diffWithBonus = diff;
		if (sign > 0) diffWithBonus = diff * this.bonusFactor(); // Bonus only applied for positive sign (when adding)

		return current + parseInt(Math.abs(diffWithBonus)) * sign;
	}

	bonusFactor() {
		if (this.hasBonusTarget && this.bonusTarget.checked) return 1.0 + parseInt(this.bonusTarget.value) / 100;

		return 1.0;
	}

	clamp(value, max) {
		const aboveZero = Math.max(value, 0);
		const parsedMax = parseInt(max);
		if (!Number.isInteger(parsedMax)) return aboveZero;

		return Math.min(aboveZero, parsedMax);
	}
}
