import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="name-generator"
export default class extends Controller {
	static targets = ["output"];
	static values = { names: Array };

	connect() {
		const defaultName = "Novo(a) Personagem";
		if (this.outputTarget.value === defaultName) {
			this.generate();
		}
	}

	generate() {
		const index = Math.floor(Math.random() * this.namesValue.length);

		this.generateFromRace(this.namesValue[index]);
	}

	generateFromRace(gendersArray) {
		const index = Math.floor(Math.random() * gendersArray.length);

		this.generateFromLists(gendersArray[index]);
	}

	generateFromLists(twoArrays) {
		this.outputTarget.value = this.pickFrom(twoArrays[0]) + this.pickFrom(twoArrays[1]);
	}

	pickFrom(array) {
		return array[Math.floor(Math.random() * array.length)];
	}
}
