import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="languages"
export default class extends Controller {
	static targets = ["template", "list"];
	static values = {
		list: { type: Array, default: [] },
	};

	connect() {
		this.load();
	}

	load() {
		this.listValue.forEach((language) => {
			const template = this.templateTarget.content.cloneNode(true);
			template.querySelector("[data-languages-target='input']").value = language;
			this.listTarget.appendChild(template);
		});
	}

	add() {
		const template = this.templateTarget.content.cloneNode(true);
		this.listTarget.appendChild(template);
	}

	remove(event) {
		event.target.closest("[data-languages-target='item']").remove();
	}
}
