import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="carousel"
export default class extends Controller {
	static targets = ["slide"];

	connect() {
		this.index = 0;
		this.startCarousel();
	}

	startCarousel() {
		this.interval = setInterval(() => {
			this.nextSlide();
		}, 3000); // Troca de slide a cada 3 segundos
	}

	nextSlide() {
		this.index = (this.index + 1) % this.slideTargets.length;
		this.showSlide(this.index);
	}

	showSlide(index) {
		const slideWidth = this.slideTargets[0].offsetWidth;
		this.element.scrollTo({
			left: slideWidth * index,
			behavior: "smooth",
		});
	}

	disconnect() {
		clearInterval(this.interval);
	}
}
