import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="side-navigation-drawer"
export default class extends Controller {
	static targets = ["drawer"];

	disconnect() {
		this.closeDrawer();
	}

	openDrawer(event) {
		event.preventDefault();
		event.stopPropagation();
		this.drawerTarget.classList.remove("translate-x-full");
		this.drawerTarget.classList.add("z-40");
		// Criando backdrop
		const backdropElement = document.createElement("div");
		backdropElement.setAttribute("drawer-backdrop", "");
		backdropElement.classList.add("bg-gray-900", "bg-opacity-50", "dark:bg-opacity-80", "fixed", "z-10", "inset-0", "md:hidden");
		document.body.classList.add("overflow-hidden");
		document.querySelector("body").append(backdropElement);
		backdropElement.addEventListener("click", this.closeDrawer.bind(this));
	}

	closeDrawer() {
		this.drawerTarget.classList.add("translate-x-full");
		this.drawerTarget.classList.remove("z-40");
		const backdrop = document.querySelector("[drawer-backdrop]");
		if (backdrop) {
			backdrop.remove();
		}
		document.body.classList.remove("overflow-hidden");
	}
}
