import { Controller } from "@hotwired/stimulus";

import browserUpdate from "browser-update";

// Connects to data-controller="browser-update"
export default class extends Controller {
	static targets = ["container"];

	connect() {
		this.browserUpdate = new browserUpdate({
			required: {
				e: -10, // https://learn.microsoft.com/en-us/deployedge/microsoft-edge-release-schedule
				f: -20, // https://www.mozilla.org/en-US/firefox/releases/
				o: -3,
				s: -1,
				c: -10,
			},
			// insecure: true,
			unsupported: true,

			// test: true,
			container: this.containerTarget,
			no_permanent_hide: true,
			text: {
				msg: "Seu navegador ({brow_name}) está desatualizado.",
				msgmore: "Atualize seu navegador para usar o site do Old Dragon.",
				bupdate: "Atualizar navegador",
				bignore: "Ignorar",
				remind: "Você será lembrado dentro de {days} dias.",
				bnever: "Não mostrar novamente",
			},
			newwindow: true,
			api: 2022.11,
		});
	}
}
