import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="prevent"
// Allows us to ignore pressing "enter" on a field and that submitting the form
export default class extends Controller {
	static targets = ["ignored"];

	prevent(event) {
		if (event.srcElement !== undefined && this.ignoredTargets.indexOf(event.srcElement) !== -1) event.preventDefault();
	}
}
