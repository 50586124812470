import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="equipment-weight-controller"
export default class extends Controller {
	static targets = ["inLoad", "inGrams", "inLoadRadio", "inGramsRadio"];

	connect() {
		if (this.inLoadTarget.value != "") {
			this.inLoadRadioTarget.checked = true;
			this.inGramsTarget.setAttribute("readonly", true);
		}
		if (this.inGramsTarget.value != "") {
			this.inGramsRadioTarget.checked = true;
			this.inLoadTarget.setAttribute("readonly", true);
		}
		if (this.inLoadTarget.value == "" && this.inGramsTarget.value == "") {
			this.inLoadRadioTarget.checked = true;
			this.inGramsTarget.setAttribute("readonly", true);
		}
	}

	update(event) {
		if (event.target.id == "in-load") {
			this.inLoadTarget.removeAttribute("readonly");
			this.inGramsTarget.setAttribute("readonly", true);
			this.inGramsTarget.value = "";
		}
		if (event.target.id == "in-grams") {
			this.inLoadTarget.setAttribute("readonly", true);
			this.inGramsTarget.removeAttribute("readonly");
			this.inLoadTarget.value = "";
		}
	}
}
